import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

export const search = async (name, location, email) => {
    try {
        const response = await axios.post(`${API_URL}/search`, {
            name,
            location,
            email,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
};

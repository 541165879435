import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../Components/Layout';
import './styles.css';
import { MissionModal, FutureModal } from "./AboutModal";

const About = () => {
    const navigate = useNavigate();
    const [ismissionOpen, setIsmissionOpen] = useState(false);
    const [isAnimatingClose, setIsAnimatingClose] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isfutureOpen, setIsfutureOpen] = useState(false);

    const closeModal = (modalType) => {
        if (isClosing) return;

        setIsClosing(true);
        setTimeout(() => {
            if (modalType === 'mission') {
                setIsmissionOpen(false);
            } else if (modalType === 'future') {
                setIsfutureOpen(false);
            }
            setIsClosing(false);
        }, 1500);
    };

    const commands = {
        ls: {
            description: 'Show everything on the page',
            fn: function () {
                return 'discord\nmission\nfuture';
            }
        },

        about: {
            description: 'You are here',
            fn: function () {
                return 'You are already at the about page';
            }
        },

        cd: {
            description: 'Go to Main page',
            fn: function () {
                setTimeout(function () {
                    navigate('/');
                }, 1200);
                return 'Redirecting to the Main Page ...';
            }
        },

        discord: {
            description: 'Discord',
            fn: function () {
                setTimeout(function () {
                    window.open('https://discord.gg/BVTFCCQS8D');
                }, 1200);
                return 'Here it comes...';
            }
        },

        mission:{
            description: 'Mission Statement',
            fn: function () {
                setTimeout(function () {
                    setIsmissionOpen(true);
                }, 1200);
                return 'Redirecting to the Mission Statement ...';
            }
        },

        future:{
            description: 'Future features',
            fn: function () {
                setTimeout(function () {
                    setIsfutureOpen(true);
                }, 1200);
                return 'Cool stuff coming soon ...';
            }
        },
    };

    return (
        <Layout commands={commands} welcomeMessage="Yoou've made it to the About page. Enter the command 'ls' to find out more. Use 'cd' to go back to the main page.">
            {ismissionOpen && !isAnimatingClose && !isClosing && (
                <MissionModal isOpen={ismissionOpen} onClose={() => closeModal('mission')} />
            )}
            {isfutureOpen && !isAnimatingClose && !isClosing && (
                <FutureModal isOpen={isfutureOpen} onClose={() => closeModal('future')} />
            )}
        </Layout>
    );
};

export default About;
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../Components/Layout'
import {MainModal} from "../Pages/AboutModal";
import { search } from '../api';

const MainLayout = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalText, setModalText] = useState('');
    const verificationCodeEnv = process.env.REACT_APP_VERIFICATION_CODE;

    const commands = {

        about: {
            description: 'Go to About page',
            fn: function () {
                setTimeout(function () {
                    navigate('/about');
                }, 1200);
                return 'Redirecting...';
            },
        },

        discord: {
            description: 'Discord',
            fn: function () {
                setTimeout(function () {
                    window.open('https://discord.gg/BVTFCCQS8D');
                }, 1200);
                return 'Here it comes...';
            }
        },

        data: {
            description: 'Collect name, location, email to search the matrix',
            fn: function () {
                const name = prompt('Please enter your name:');
                const email = prompt('Please enter your email:');
                const state = [prompt('Please enter your state')];
                const verificationCode = prompt('Please enter the verification code provided:');

                if (name && state && email) {
                    if (verificationCode === verificationCodeEnv) {
                        const userInfo = { name, email, state };
                        sendToBackend(userInfo);
                        return 'Searching............. \n Have some patience';
                    } else {
                        return 'Invalid verification code';
                    }
                } else {
                    return 'Hmm, looks like we need the information to proceed!';
                }
            },
        },
    };

    function sendToBackend(userInfo) {
        search(userInfo.name, userInfo.location, userInfo.email)
            .then(data => {
                console.log('Success:', data);
                setModalText(JSON.stringify(data, null, 2));
                setIsModalOpen(true);
            })
            .catch(error => {
                console.error('Error:', error);
                alert('An error occurred. Check console for details.');
            });
    }

    return (
        <>
            <Layout
                commands={commands}
                welcomeMessage="Heello, I’m Incognito, your personal guide through the Matrix! Give me your name, location, and email using the command 'data', and I'll uncover everything you need. If you want to learn more about this world, just use the command 'about'. Let's get started!"
            />
            <MainModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                modalText={modalText}
            />
        </>
    );
};

export default MainLayout;
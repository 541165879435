import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from './Components/MainLayout';
import About from './Pages/About';


const App = () => (
    <Router>
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/" element={<MainLayout />} />
      </Routes>
    </Router>
);

export default App;